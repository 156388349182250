import tmerc from './lib/projections/tmerc';
import etmerc from './lib/projections/etmerc';
import utm from './lib/projections/utm';
import sterea from './lib/projections/sterea';
import stere from './lib/projections/stere';
import somerc from './lib/projections/somerc';
import omerc from './lib/projections/omerc';
import lcc from './lib/projections/lcc';
import krovak from './lib/projections/krovak';
import cass from './lib/projections/cass';
import laea from './lib/projections/laea';
import aea from './lib/projections/aea';
import gnom from './lib/projections/gnom';
import cea from './lib/projections/cea';
import eqc from './lib/projections/eqc';
import poly from './lib/projections/poly';
import nzmg from './lib/projections/nzmg';
import mill from './lib/projections/mill';
import sinu from './lib/projections/sinu';
import moll from './lib/projections/moll';
import eqdc from './lib/projections/eqdc';
import vandg from './lib/projections/vandg';
import aeqd from './lib/projections/aeqd';
import ortho from './lib/projections/ortho';
import qsc from './lib/projections/qsc';
import robin from './lib/projections/robin';
import geocent from './lib/projections/geocent';
import tpers from './lib/projections/tpers';
export default function(proj4){
  proj4.Proj.projections.add(tmerc);
  proj4.Proj.projections.add(etmerc);
  proj4.Proj.projections.add(utm);
  proj4.Proj.projections.add(sterea);
  proj4.Proj.projections.add(stere);
  proj4.Proj.projections.add(somerc);
  proj4.Proj.projections.add(omerc);
  proj4.Proj.projections.add(lcc);
  proj4.Proj.projections.add(krovak);
  proj4.Proj.projections.add(cass);
  proj4.Proj.projections.add(laea);
  proj4.Proj.projections.add(aea);
  proj4.Proj.projections.add(gnom);
  proj4.Proj.projections.add(cea);
  proj4.Proj.projections.add(eqc);
  proj4.Proj.projections.add(poly);
  proj4.Proj.projections.add(nzmg);
  proj4.Proj.projections.add(mill);
  proj4.Proj.projections.add(sinu);
  proj4.Proj.projections.add(moll);
  proj4.Proj.projections.add(eqdc);
  proj4.Proj.projections.add(vandg);
  proj4.Proj.projections.add(aeqd);
  proj4.Proj.projections.add(ortho);
  proj4.Proj.projections.add(qsc);
  proj4.Proj.projections.add(robin);
  proj4.Proj.projections.add(geocent);
  proj4.Proj.projections.add(tpers);
}